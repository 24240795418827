import React from "react"
import Layout from "../components/simpleLayout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import "../utils/css/components/home.css"
import siteConfig from '../../config'

const Videos = () => {
// const data = useStaticQuery(graphql`
//     query YouTubeVideoQuery {
//   allYoutubeVideo(sort: {fields: publishedAt, order: DESC}, limit: 10, filter: {}) {
//     totalCountupda
//     nodes {
//       videoId
//     }
//     pageInfo {
//       currentPage
//       hasNextPage
//       hasPreviousPage
//       pageCount
//     }
//   }
// }
//   `)


//   const h =  data.allYoutubeVideo.nodes.map((id, key) => {
//        let src = "https://www.youtube.com/embed/"+ id.videoId+"?rel=0&showinfo=0&modestbranding=1";
//       let html = ''
//       if(key % 2 === 0) {
//         html = `
//         <div className="row">
//            <div className="col-6 first">
//              <iframe key={key} className="youtubeVideo" src=`+src+` frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
//            </div>
//         `
//       }else {
//         html = `
//         <div className="col-6 second">
//             <iframe  key={key}   className="youtubeVideo"  src=`+src+` frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
//         </div>
//       </div>

//         `
//       }

//       return html          
          
//      }).join('')



  return (
  <Layout>
    <SEO title="Videos by Asish George" keywords={siteConfig.keywords}/>
   
    <section className="container">
     
     <h1 className="heading">Videos</h1>
     
      {/* <div className="container" dangerouslySetInnerHTML={{__html: h}} ></div> */}
    <div className="container">

      <div className="row">
        <div className="col-6 first">
          <iframe className="youtubeVideo" title="eka highlights" src="https://www.youtube.com/embed/tsFF61nNG5U" frameBorder="0" allow="autoplay; accelerometer; encrypted-media; clipboard-write; gyroscope; picture-in-picture" allowFullScreen></iframe>

        </div>
        <div className="col-6 second">
          <iframe className="youtubeVideo" title ="rebuild kerala" src="https://www.youtube.com/embed/lCaQjSK4vbI" frameBorder="0" allow="autoplay; accelerometer; encrypted-media; clipboard-write; gyroscope; picture-in-picture" allowFullScreen></iframe>

        </div>
       
    </div>





      <div className="row">
        <div className="col-6 first">
          <iframe className="youtubeVideo" title="iv" src="https://www.youtube.com/embed/videoseries?list=PLumMN4MlwyGsHjgIdWGTqbgh-p6S-GVmI" frameBorder="0" allow="autoplay; accelerometer; encrypted-media; clipboard-write; gyroscope; picture-in-picture" allowFullScreen></iframe>

        </div>
        <div className="col-6 second">
          <iframe className="youtubeVideo" title="ag tech" src="https://www.youtube.com/embed/videoseries?list=PLX7mEGqtfnSo1tBFhE4nqwimFnixJTPPI" frameBorder="0" allow="autoplay; accelerometer; encrypted-media; clipboard-write; gyroscope; picture-in-picture" allowFullScreen></iframe>

        </div>
       
    </div>

     {/* <div className="row">
        <div className="col-6 first">
          <iframe className="youtubeVideo" title="indian companies" src="https://www.youtube.com/embed/videoseries?list=PLumMN4MlwyGvoJnts_PhceLHoh0R5MD4Y" frameBorder="0" allow="autoplay; accelerometer; encrypted-media; clipboard-write; gyroscope; picture-in-picture" allowFullScreen></iframe>

        </div>
        <div className="col-6 second">
          <iframe className="youtubeVideo" title="samridhi" src="https://www.youtube.com/embed/t7MNwbg-w_o" frameBorder="0" allow="autoplay; accelerometer; encrypted-media; clipboard-write; gyroscope; picture-in-picture" allowFullScreen></iframe>

        </div>

      </div> */}
      
     <div className="row">
        <div className="col-6 first">
          <button className="button"  onClick={e => window.open('https://www.youtube.com/asishgeorgeoriginals', '_blank') }>
       
              Visit Asish George on YouTube
           
          </button>

        </div>
        <div className="col-6 second">
          <button className="button" onClick={e => window.open('https://www.youtube.com/asishgeorgetech', '_blank') }>
            
               Visit AG Tech on YouTube
   
          </button>
          
        </div>

      </div>
    </div>
    </section>
  </Layout>)
}

export default Videos
